// import axios
import { axiosInstance } from '@/plugins/axios'

export class LookupService {
  constructor (axios) {
    this.axios = axios
  }

  fetchVehicles () {
    // TODO: Refactor implementations to a typeahead api for a server side search with associated memoization
    return this.axios.get('/v1/vehicles?limit=10000').then((res) => res.data?.data || [])
  }

  fetchVehicleCategories (options = {}) {
    return this.axios.get('/v1/vehicle-types', options)
  }

  fetchDrivers () {

  }

  fetchRoutes () {

  }

  fetchCities () {
    return this.axios.get('/v1/city/all?limit=1000&fields=id,name').then((res) => res.data?.data || [])
  }

  fetchCompanies () {
    // TODO: get a leaner version of api to load corporates
    //  with less attributes for filtering or lookup purposes
    //  Review Backend Api to do without limit specification
    return this.axios.get('/v1/corporates?limit=100000').then((res) => res.data?.data || [])
  }
}

export const lookupService = new LookupService(axiosInstance)
