<template>
  <div class="card mt-5">
    <div class="card-body p-2">
      <template v-if="!loadingDrivers">
        <!-- <h3 class="card-title driver-card-title">
          Associated Drivers & Vehicles
          <div class="flex gap-3">
            <b-button v-if="itineraryDrivers.length > 1" variant="primary" :disabled='isEditingDraggableLoading' class="btn btn-primary float-right btn-sm" @click="isEditingDraggable ? savePos() : isEditingDraggable = true">
             <span v-if='!isEditingDraggableLoading'>{{ isEditingDraggable ? 'Save' : 'Reposition' }}</span> 
             <span v-else>Loading...</span>
            </b-button>
            <b-button v-if="hasDriverData && userHasPermission('routes011')" variant="primary" class="btn btn-primary float-right btn-sm" @click="openDriverModal()">
              Add Driver
            </b-button>
          </div>
        </h3> -->
        <div class="flex items-center justify-between border-b gap-4 flex-col md:flex-row mb-4 p-2">
          <h3 class="m-0 text-[#101211] font-medium">Associated Drivers & Vehicles</h3>
          <div class="flex gap-3 items-stretch">
            <router-link v-if="DoNotShowOnProd()" :to="{ name: 'CreateItinerariesDriverAuction', params: { routeId: $route.params.routeId }, query: {q: $route.params.itineraryId} }" class="btn bg-transparent text-black border !border-[black]">
              Auction route itinerary
            </router-link>
            <b-button  variant="primary" class="btn btn-primary float-right btn-sm" @click="openDriverModal()">
              Add Driver
            </b-button>
          </div>
        </div>

        <div class="w-full overflow-auto">
          <table class="table">
            <thead v-if="hasDriverData">
              <tr>
                <th>Id</th>
                <th>Driver</th>
                <th>Vehicle</th>
                <th>Supply Cost</th>
                <th v-if="itinerary.pricing_type !== 'route_price_table_lookup'">Utilization</th>
                <th v-if="itinerary.pricing_type !== 'route_price_table_lookup'">Margin</th>
                <th v-if="itinerary.pricing_type !== 'route_price_table_lookup'">Fare</th>
                <th></th>
              </tr>
            </thead>

            <tr v-if="!hasDriverData && userHasPermission('routes011')">
              <td class="">
                <!-- <div class="text-center">
                  <b-button @click="openDriverModal()" variant="primary">Add Driver</b-button>
                </div> -->
                <!-- <p class="!text-center">No Vehicle and Driver assigned. You can auction this route or manually assign a Vehicle and Driver to it.</p> -->
                <div class="flex flex-col gap-1 items-center w-full">
                  <img src="@/assets/img/empty.svg" alt="">
                  <p class="text-xs text-center text-[#6E717C] font-medium">No Vehicle and Driver assigned. You can manually assign a Vehicle and Driver to it.</p>
                </div>
              </td>
            </tr>

            

            <draggable v-model="itineraryDrivers" tag="tbody" :disabled='!isEditingDraggable' >
              <tr v-for="(driver, index) in itineraryDrivers" :key="driver.driver_id">
                <td>
                  {{ driver.driver_id }}
                </td>
                <td>
                  <router-link v-if="driver" class="text-primary" title="Click to view driver profile" :to="{
                    name: 'ShowDriver',
                    params: { driverId: driver.driver_id },
                  }">
                    <span class="link">
                      {{ driver.driver.fname }} {{ driver.driver.lname }} -
                      {{ driver.driver.phone }}
                    </span>
                  </router-link>
                </td>
                <td>
                  <router-link class="text-primary" v-if="driver.vehicle_id" :to="{
                    name: 'ShowVehicle',
                    params: { vehicleId: driver.vehicle_id },
                  }">
                    <span class="link">{{ getDriverVehicle(driver) }}</span>
                  </router-link>
                </td>
                <td>
                  <span v-if="driver.cost_of_supply">{{ itinerary.default_fare_currency }} {{ driver.cost_of_supply
                  }}</span>
                  <span v-else>N/A</span>
                </td>

                <td v-if="itinerary.pricing_type !== 'route_price_table_lookup'">
                  <span v-if="driver.break_even_utilization"> {{ driver.break_even_utilization * 100.0 }}%</span>
                  <span v-else>N/A</span>
                </td>

                <td v-if="itinerary.pricing_type !== 'route_price_table_lookup'">
                  <span v-if="driver.pricing_margin_unit && driver.pricing_margin"> {{ driver.pricing_margin }} {{
                    driver.pricing_margin_unit }}</span>
                  <span v-else>N/A</span>
                </td>

                <td v-if="itinerary.pricing_type !== 'route_price_table_lookup'">
                  <span v-if="driver.max_fare"> NGN {{ driver.max_fare }}</span>
                  <span v-else>-</span>
                </td>

                <td class="text-right">
                  <b-dropdown size="sm" variant="link" right class="position-relative text-center w-100" :toggle-class="'text-decoration-none'" :no-caret="true">
                    <template #button-content>
                      <div class="w-100 text-center">
                        <i class="fe fe-more-vertical"></i>
                      </div>
                    </template>
                    <template>
                      <b-dropdown-item @click.prevent.stop="openDriverModal(driver)" v-if="userHasPermission('routes012')">
                        Update
                      </b-dropdown-item>
                      <b-dropdown-item v-if="itineraryDrivers.length > 1" @click.prevent.stop="openBookingTransferModal(driver)">
                        Transfer Booking
                      </b-dropdown-item>
                      <b-dropdown-item link-class="text-danger" @click.prevent.stop="removeDriver(driver)" v-if="userHasPermission('routes012')">
                        Remove
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </td>
              </tr>
            </draggable>


          </table>
        </div>
        
      </template>
      <template v-else>
        <div class="
                    d-flex
                    flex-column
                    py-5
                    justify-content-center
                    align-items-center
                    w-100
                  ">
          <div class="spinner-border" role="status"></div>
          <p>Loading Drivers...</p>
        </div>
      </template>
      <div v-if="DoNotShowOnProd()" class="p-4 bg-[#FAFAFA] rounded border flex flex-col gap-2 mt-3">
        <h3 class="text-sm font-medium text-[#101211] pb-3 border-bottom">Auctions</h3>
        <div class="w-full overflow-auto">
          <div class="min-w-[500px] flex flex-col gap-4">
            <div class="w-full grid grid-cols-5 gap-4">
              <p class="text-xs font-medium text-[#6E717C]">Preferred vehicle</p>
              <p class="text-xs font-medium text-[#6E717C]">Trip start time</p>
              <p class="text-xs font-medium text-[#6E717C]">Auction start time</p>
              <p class="text-xs font-medium text-[#6E717C]">Status</p>
              <p class="text-xs font-medium text-[#6E717C]">Bids</p>
            </div>
            
            <div class="w-full grid grid-cols-5 gap-4 items-start">
              <div class="flex flex-col gap-1">
                <p class="text-[13px] text-[#313533] font-medium">Toyota Sedan 2022</p>
                <p class="text-[13px] text-[#313533] font-medium">(24 seats)</p>
              </div>
              <p class="text-[13px] text-[#313533] font-medium">Wed Oct 29 2023</p>
              <div class="flex flex-col gap-1">
                <p class="text-[13px] text-[#313533] font-medium">11:00 AM</p>
                <p class="text-[13px] text-[#313533] font-medium">Wed Oct 29 2023</p>
              </div>
              <p class="text-xs bg-[#FFFCEB] !border !border-[#FFDF18] rounded p-2 w-fit text-[#313533] font-medium">Pending</p>
              <button class="text-sm font-bold text-[#313533] w-fit bg-transparent border-none">...</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create/Update Driver -->
    <b-modal id="add-driver" :title="editMode ? 'Update Driver' : 'Add Driver'" no-close-on-esc no-close-on-backdrop @close="resetDriverForm()">
      <div>
        <label>Select Driver</label>
        <VueSelect :multiple="false" v-model="driverForm.driver" @input="onDriverSelected($event)" track-by="id" :custom-label="fullName" :option-height="104" :options="drivers">
          <template slot="singleLabel" slot-scope="props">
            <div class="media">
              <img v-if="props.option && props.option.avatar" class="
                          avatar avatar-sm avatar-img
                          rounded-circle
                          option__image
                          mr-2
                        " style="width: 40px; height: 40px" :src="props.option.avatar" :alt="props.option.fname" />
              <div class="option__desc media-body">
                <span class="option__title">{{ props.option.fname }} {{ props.option.lname }}</span>
                <div class="option__small text-small" v-if="props.option.vehicle">
                  <small>
                    {{ props.option.vehicle.brand }}
                    {{ props.option.vehicle.name }}
                    {{
                      props.option.vehicle.registration_number
                      ? ` • ${props.option.vehicle.registration_number}`
                      : ''
                    }}
                    {{
                      props.option.vehicle.seats
                      ? ` • ${props.option.vehicle.seats} seater`
                      : ''
                    }}
                  </small>
                </div>
                <div v-else-if="props.option.fname">
                  <small>No vehicle assigned</small>
                </div>
              </div>
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div class="media">
              <img v-if="props.option" class="
                          avatar avatar-sm avatar-img
                          rounded-circle
                          option__image
                          mr-2
                        " style="width: 40px; height: 40px" :src="props.option.avatar || userAvatar" :alt="props.option.fname" />
              <div class="option__desc media-body">
                <span class="option__title">{{ props.option.fname }} {{ props.option.lname }}</span>
                <div class="option__small text-small" v-if="props.option.vehicle">
                  <small style="word-break: break-word">
                    {{ props.option.vehicle.brand }}
                    {{ props.option.vehicle.name }}
                    {{
                      props.option.vehicle.registration_number
                      ? ` • ${props.option.vehicle.registration_number}`
                      : ''
                    }}
                    {{
                      props.option.vehicle.seats
                      ? ` • ${props.option.vehicle.seats} seater`
                      : ''
                    }}
                  </small>
                </div>
                <div v-else-if="props.option">
                  <small>No vehicle assigned</small>
                </div>
              </div>
            </div>
          </template>
        </VueSelect>
      </div>
      <div v-if="editMode">
        <div class="mt-3 d-inline-flex">
          <label>Update vehicle</label>
          <b-form-checkbox class="ml-2" v-model="driverForm.updateVehicle" :indeterminate="false">
          </b-form-checkbox>
        </div>

        <div class="form-group">
          <v-select :disabled="!driverForm.updateVehicle" v-model="driverForm.vehicle" class="form-group" :options="vehicles" label="registration_number" required placeholder="Select a vehicle to assign">
            <template v-slot:option="vehicle">
              {{ vehicle.brand }} • {{ vehicle.name }}
              {{ vehicle.registration_number }} • {{ vehicle.seats }} Seater
            </template>
            <template v-slot:selected-option="vehicle">
              {{ vehicle.brand }} • {{ vehicle.name }}
              {{ vehicle.registration_number }} • {{ vehicle.seats }} Seater
            </template>
          </v-select>
        </div>
      </div>

      <div class="form-group col-12 px-0">
        <label class="form-label">Cost of supply</label>
        <input v-model="driverForm.costOfSupply" required type="number" placeholder="N" class="form-control" />
      </div>

      <div class="row" v-if="itinerary.pricing_type !== 'route_price_table_lookup'">
        <div class="col-4">
          <div class="form-group">
            <label class="form-label">Seat Utilization <b-icon v-b-popover.click.top="'This figure is used to determine the percentage of total seats available that we need to sell to generate revenue on this vehicle type'" title="Seat Utilization" variant="info" icon="info-circle-fill"></b-icon>
            </label>
            <input v-model.number="driverForm.break_even_utilization" placeholder="between 0.0 to 1.0" class="form-control mb-3" type="text">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="form-label">Pricing margin</label>
            <input v-model.number="driverForm.pricing_margin" :placeholder="`In ${driverForm.pricing_margin_unit}`" class="form-control mb-3" type="text">

          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="form-label">Pricing margin unit</label>
            <select v-model="driverForm.pricing_margin_unit" class="mb-3 custom-select">
              <option value="percent">Percent</option>
              <option value="flat">Flat Amount</option>
            </select>

          </div>
        </div>
      </div>

      <template #modal-footer>
        <button type="button" class="btn btn-secondary mr-3" data-dismiss="modal" ref="closeModalButton" @click="closeDriverModal()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click.prevent="addDriver" :disabled="processing || !driverForm.driver || !driverForm.vehicle || !driverForm.costOfSupply || !driverForm.pricing_margin || !driverForm.pricing_margin_unit || !driverForm.break_even_utilization">
          {{ processing ? 'Processing...' : editMode ? 'Update' : 'Add' }}
        </button>
      </template>
    </b-modal>

    <!-- Booking transfer -->
    <b-modal id="transfer-bookings-modal" :title="'Transfer Bookings'" no-close-on-esc no-close-on-backdrop :hide-header-close="processingTransfer" @cancel="resetBookingTransferForm()" @close="resetBookingTransferForm()">
      <div v-if="transferVerificationStep">
        <div class="alert alert-info d-flex align-items-center" role="alert">
          <img class="mr-2" src="@/assets/img/caution.svg" />
          <p class="mb-0">It’s important you read the message below!</p>
        </div>
        <p class="mt-3 mb-3">{{ verificationMessage }}</p>
        <p class="mb-3">
          Type YES and click proceed to request booking transfer.
        </p>
        <div class="form mb-3">
          <div class="form-group">
            <input v-model="bookingTransfer.confirmationKey" class="form-control mb-3" :placeholder="`Type YES`" autocomplete="new-password" />
          </div>
        </div>
      </div>
      <div v-else>
        <label>Select New Driver</label>
        <VueSelect :multiple="false" v-model="bookingTransfer.newDriver" :custom-label="driverDescription" track-by="id" :option-height="104" :options="bookingTransferDrivers">
          <template slot="singleLabel" slot-scope="props">
            <div class="media">
              <img v-if="props.option.driver && props.option.driver.avatar" class="
                          avatar avatar-sm avatar-img
                          rounded-circle
                          option__image
                          mr-2
                        " style="width: 40px; height: 40px" :src="props.option.driver.avatar" :alt="props.option.driver.fname" />
              <div class="option__desc media-body">
                <span class="option__title">{{ props.option.driver.fname }}
                  {{ props.option.driver.lname }}</span>
                <div class="option__small text-small" v-if="props.option.vehicle">
                  <small>
                    {{ props.option.vehicle.brand }}
                    {{ props.option.vehicle.name }}
                    {{
                      props.option.vehicle.registration_number
                      ? ` • ${props.option.vehicle.registration_number}`
                      : ''
                    }}
                    {{
                      props.option.vehicle.seats
                      ? ` • ${props.option.vehicle.seats} seater`
                      : ''
                    }}
                  </small>
                </div>
                <div v-else-if="props.option.driver.fname">
                  <small>No vehicle assigned</small>
                </div>
              </div>
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div class="media">
              <img v-if="props.option" class="
                          avatar avatar-sm avatar-img
                          rounded-circle
                          option__image
                          mr-2
                        " style="width: 40px; height: 40px" :src="props.option.avatar || userAvatar" :alt="props.option.driver.fname" />
              <div class="option__desc media-body">
                <span v-if="props.option.driver" class="option__title">{{ props.option.driver.fname }}
                  {{ props.option.driver.lname }}</span>
                <div class="option__small text-small" v-if="props.option.vehicle">
                  <small style="word-break: break-word">
                    {{ props.option.vehicle.brand }}
                    {{ props.option.vehicle.name }}
                    {{
                      props.option.vehicle.registration_number
                      ? ` • ${props.option.vehicle.registration_number}`
                      : ''
                    }}
                    {{
                      props.option.vehicle.seats
                      ? ` • ${props.option.vehicle.seats} seater`
                      : ''
                    }}
                  </small>
                </div>
                <div v-else-if="props.option.driver">
                  <small>No vehicle assigned</small>
                </div>
              </div>
            </div>
          </template>
        </VueSelect>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button :disabled="processingTransfer ||
            (transferVerificationStep && !isValidVerification)
            " variant="primary" class="float-right" @click="requestBookingTransfer()">
            {{ processingTransfer ? 'Processing' : 'Proceed' }}
          </b-button>
          <b-button :disabled="processingTransfer" variant="secondary" class="float-right mr-2" @click="closeBookingTransferModal()">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script setup>
import { userHasPermission, DoNotShowOnProd } from '@/composables/core/permissions'
</script>

<script>
import draggable from 'vuedraggable'
import VueSelect from 'vue-multiselect'
import { isValid } from 'date-fns'
import { extractErrorMessage, filterVehicleBy } from '@/utils/helpers'
import { lookupService } from '@/services/lookup'

export default {
  name: 'ItineraryDriverSettings',
  components: { VueSelect, draggable },
  props: {
    itinerary: { type: Object, default: null, required: true },
  },
  data()
  {
    return {
      isEditingDraggableLoading:false,
      isEditingDraggable: false,
      drivers: [],
      vehicles: [],
      itineraryDriver: {
        id: null
      },
      costOfSupply: '',
      selectedDriver: null,
      itineraryDrivers: [],
      loadingDrivers: false,
      bookingTransfer: {
        oldDriver: null, // Itinerary driver-vehicle model record (Not driver model) - Selected Target
        newDriver: null, // Itinerary driver-vehicle record (Not driver model) -New Selection
        confirmationKey: null
      },
      processingTransfer: false,
      transferVerificationStep: false,
      verificationMessage: '',
      editMode: false,
      driverForm: {
        id: null,
        driver: null,
        vehicle: null,
        updateVehicle: false,
        costOfSupply: null,
        pricing_margin: 10,
        pricing_margin_unit: 'percent',
        break_even_utilization: 1.0
      },
      processing: false,
      existingCostOfSupply: null
    }
  },
  watch: {
    itinerary()
    {
      this.fetchItineraryDrivers()
    },
    'driverForm.vehicle': function (newval, oldval)
    {
      if (this.driverForm.id) return
      if (this.itineraryHasPricingMarginConfig) return

      if (!newval) {
        return
      }

      if (!newval.vehicle_type) {
        return
      }

      if (!newval.vehicle_type.pricing_margin) {
        return
      }

      if (!newval.vehicle_type.pricing_margin_unit) {
        return
      }

      if (!newval.vehicle_type.break_even_utilization) {
        return
      }

      this.driverForm.pricing_margin = newval.vehicle_type.pricing_margin
      this.driverForm.pricing_margin_unit = newval.vehicle_type.pricing_margin_unit
      this.driverForm.break_even_utilization = newval.vehicle_type.break_even_utilization
    }
  },
  computed: {
    isValid()
    {
      return !this.driverForm.driver || !this.driverForm.vehicle || !this.driverForm.costOfSupply || !this.driverForm.pricing_margin || !this.driverForm.pricing_margin_unit || !this.driverForm.break_even_utilization
    },
    itineraryHasPricingMarginConfig()
    {
      if (!this.itinerary.pricing_margin) {
        return false
      }

      if (!this.itinerary.pricing_margin_unit) {
        return false
      }

      if (!this.itinerary.break_even_utilization) {
        return false
      }

      return true
    },
    hasDriverData()
    {
      return this.itineraryDrivers?.length
    },
    userAvatar()
    {
      return require('@/assets/img/profile.png')
    },
    bookingTransferDrivers()
    {
      return this.bookingTransfer.oldDriver
        ? this.itineraryDrivers.filter(
          (itineraryDriver) =>
            itineraryDriver.id !== this.bookingTransfer.oldDriver?.id
        )
        : []
    },
    isValidVerification()
    {
      return this.bookingTransfer.confirmationKey?.toLowerCase() === 'yes'
    }
  },
  created()
  {
    this.loadPageData()
    this.prefillPricingInfo()
    this.fetchItineraryDrivers()
  },
  mounted()
  {
    if (this.$route.query.addVehicle) {
      this.$bvModal.show('add-driver')
    }
  },
  methods: {
    savePos(){
      this.isEditingDraggableLoading = true
      const sent_data = this.itineraryDrivers.map((item, i) =>
      {
       return { 'id': item.id,  position:i+1}
      })
      this.axios
        .patch('/v1/route-vehicles', sent_data)
        .then((res) =>
        {
          this.isEditingDraggableLoading = false
          this.isEditingDraggable = false
            this.$swal({
            icon: 'success',
            title: 'Position Saved',
            text: 'Positions have been rearranged successfully',
            showCloseButton: true
          })
        })
        .finally(() => (this.loadingDrivers = false))
    },
    prefillPricingInfo()
    {
      if (this.itinerary.pricing_margin) {
        this.driverForm.pricing_margin = this.itinerary.pricing_margin
      }
      if (this.itinerary.pricing_margin_unit) {
        this.driverForm.pricing_margin_unit = this.itinerary.pricing_margin_unit
      }

      this.driverForm.break_even_utilization = this.itinerary.break_even_utilization || 1
    },
    fullName({ fname, lname })
    {
      return `${fname} ${lname}`
    },
    vehicleFilter(option, label, search)
    {
      return filterVehicleBy(option, label, search)
    },
    fetchItineraryDrivers()
    {
      this.loadingDrivers = true
      this.axios
        .get(`/v1/route-itineraries/${this.itinerary.id}/vehicles`, { status: 'active' })
        .then((res) =>
        {
          this.itineraryDrivers = res.data.data
        })
        .finally(() => (this.loadingDrivers = false))
    },
    async loadPageData()
    {
      this.$Progress.start()
      try {
        const params = {
          related: 'vehicle',
          status: 'active',
          fields: 'id,vehicle_id,fname,lname,email,phone,avatar'
        }
        const result = await this.axios.get('/v1/drivers', { params })
        if (result.data) {
          this.drivers = result.data.map((obj) =>
          {
            if (!obj.vehicle) {
              obj.vehicle = null
            }
            return obj
          })
        }
        this.vehicles = await lookupService.fetchVehicles()
        this.$Progress.finish()
      } catch (e) {
        this.$Progress.fail()
      }
    },
    removeDriver(driver)
    {
      this.axios
        .delete(`/v1/drivers/${driver.driver_id}/routes`, {
          data: {
            route_id: this.itinerary.route_id,
            itinerary_id: this.itinerary.id
          }
        })
        .then(() =>
        {
          this.fetchItineraryDrivers()
          this.$swal({
            icon: 'success',
            title: 'Driver removed successfully',
            text: 'Driver has been removed from itinerary',
            showCloseButton: true
          })
        })
        .catch((e) =>
        {
          this.$swal({
            icon: 'error',
            title: 'Failed to remove driver!',
            text: extractErrorMessage(e),
            showCloseButton: true
          })
        })
    },
    openDriverModal(driverData = null)
    {
      this.editMode = !!driverData
      this.prefillPricingInfo()

      if (driverData) {
        this.driverForm.id = driverData.id
        this.driverForm.driver = {
          ...driverData.driver,
          vehicle: driverData.vehicle
        }
        this.driverForm.vehicle = driverData.vehicle
        this.driverForm.costOfSupply = driverData.cost_of_supply

        if (this.editMode) {
          this.driverForm.pricing_margin = driverData.pricing_margin
          this.driverForm.pricing_margin_unit = driverData.pricing_margin_unit
          this.driverForm.break_even_utilization = driverData.break_even_utilization
        }
      }
      this.$nextTick(() =>
      {
        this.$bvModal.show('add-driver')
      })
    },
    refreshView()
    {
      this.fetchItineraryDrivers()
      this.closeDriverModal()
      this.$bvModal.hide('add-driver')
    },
    addDriver()
    {
      if (!this.driverForm.driver.id) {
        this.$toastr.e('Please select a driver', 'Oops')
        return
      }
      if (this.editMode) {
        const payload = {
          driver_id: this.driverForm.driver.id,
          cost_of_supply: Number(this.driverForm.costOfSupply),
          vehicle_id: this.driverForm.vehicle.id
        }

        if (this.itinerary.pricing_type !== 'route_price_table_lookup') {
          if (typeof this.driverForm.break_even_utilization === 'number') {
            payload.break_even_utilization = this.driverForm.break_even_utilization
          }

          if (typeof this.driverForm.pricing_margin_unit === 'string') {
            payload.pricing_margin_unit = this.driverForm.pricing_margin_unit
          }

          if (typeof this.driverForm.pricing_margin === 'number') {
            payload.pricing_margin = this.driverForm.pricing_margin
          }
        }

        this.processing = true
        this.axios
          .patch(`/v1/route-vehicle/${this.driverForm.id}`, payload)
          .then(() =>
          {
            this.$swal({
              icon: 'success',
              title: 'Itinerary driver updated successfully!',
              text: '',
              showCloseButton: true
            })
            this.refreshView()
          })
          .catch((e) =>
          {
            const errorMessage = extractErrorMessage(e)
            this.$toastr.e(errorMessage, 'Failed to attach driver!')
          })
          .finally(() =>
          {
            this.processing = false
          })
      } else {
        const payload = {
          route_id: this.itinerary.route_id,
          cost_of_supply: Number(this.driverForm.costOfSupply),
          itinerary_id: this.itinerary.id
        }

        if (this.itinerary.pricing_type !== 'route_price_table_lookup') {
          if (typeof this.driverForm.break_even_utilization === 'number') {
            payload.break_even_utilization = this.driverForm.break_even_utilization
          }

          if (typeof this.driverForm.pricing_margin_unit === 'string') {
            payload.pricing_margin_unit = this.driverForm.pricing_margin_unit
          }

          if (typeof this.driverForm.pricing_margin === 'number') {
            payload.pricing_margin = this.driverForm.pricing_margin
          }
        }

        this.processing = true
        this.axios
          .post(`/v1/drivers/${this.driverForm.driver.id}/routes`, payload)
          .then(() =>
          {
            this.$swal({
              icon: 'success',
              title: 'Itinerary driver added successfully!',
              text: '',
              showCloseButton: true
            })
            this.refreshView()
          })
          .catch((e) =>
          {
            const errorMessage = extractErrorMessage(e)
            this.$toastr.e(errorMessage, 'Failed to attach driver!')
          })
          .finally(() =>
          {
            this.processing = false
          })
      }
    },
    getDriverVehicle(driverData)
    {
      if (driverData.vehicle_id && driverData.vehicle) {
        const { seats, name, registration_number } = driverData.vehicle
        return `${seats} seater - ${name} ${registration_number ? `(${registration_number})` : ''
          }`
      } else {
        return 'N/A'
      }
    },
    resetDriverForm()
    {
      this.editMode = false
      this.driverForm = {
        id: null,
        driver: null,
        vehicle: null,
        updateVehicle: false,
        costOfSupply: null,
        pricing_margin: 10,
        pricing_margin_unit: 'percent',
        break_even_utilization: 1.0
      }
      this.prefillPricingInfo()
    },
    vehicleName(param)
    {
      if (!param) return ''
      return `${param.brand} ${param.name} ${param.type}`
    },
    driverName(param, driverModel = true)
    {
      if (!param) return ''
      return `${driverModel ? param.fname : param.driver.fname} ${driverModel ? param.lname : param.driver.lname
        } • ${this.vehicleName(param.vehicle)}`
    },
    driverDescription(data)
    {
      if (!data) return ''
      return this.driverName(data, false)
    },
    driverRecordDescription(data)
    {
      if (!data) return ''
      return `${this.driverName(data, false)} • ${data.vehicle.seats} seater`
    },
    openBookingTransferModal(driver)
    {
      this.bookingTransfer.oldDriver = driver
      this.$bvModal.show('transfer-bookings-modal')
    },
    resetBookingTransferForm()
    {
      this.bookingTransfer = {
        oldDriver: null,
        newDriver: null,
        confirmationKey: null
      }
      this.selectedDriver = null
      this.transferVerificationStep = false
      this.verificationMessage = null
    },
    requestBookingTransfer()
    {
      // validate
      if (!this.bookingTransfer.newDriver?.id) {
        this.$toastr.e('Select a driver')
        return
      }

      const delay = (ms) =>
      {
        return (res) =>
        {
          return new Promise((resolve) => setTimeout(() => resolve(res), ms))
        }
      }
      const confirmProcessState = (payload) =>
      {
        return () =>
        {
          return this.axios.get('/v1/route-vehicles/transfers', {
            params: payload
          })
        }
      }

      if (this.transferVerificationStep) {
        const payload = {
          from_route_vehicle_id: this.bookingTransfer.oldDriver?.id,
          to_route_vehicle_id: this.bookingTransfer.newDriver?.id
        }
        this.processingTransfer = true
        this.axios
          .post('/v1/route-vehicles/transfers', payload)
          .then(delay(3000))
          .then(confirmProcessState(payload))
          .then((res) =>
          {
            if (res?.data?.success === true) {
              this.$swal({
                icon: 'success',
                title: 'Operation successful!',
                text: 'Booking transfer request processed',
                showCloseButton: true
              })
            } else {
              this.$swal({
                icon: 'error',
                title: 'Operation failed!',
                text:
                  res?.data?.message ||
                  'An error occurred while processing the trip bookings transfer',
                showCloseButton: true
              })
            }
            this.closeBookingTransferModal()
          })
          .catch((e) =>
          {
            const errorMessage = extractErrorMessage(
              e,
              'Failed to process transfer !'
            )
            this.$toastr.e(errorMessage)
            this.transferVerificationStep = false
          })
          .finally(() => (this.processingTransfer = false))
      } else {
        this.transferVerificationStep = true
        this.verificationMessage =
          'Are you sure you want to transfer all active/upcoming trip bookings for ' +
          `${this.driverRecordDescription(
            this.bookingTransfer.oldDriver
          )} to this selected driver ` +
          `(${this.driverRecordDescription(this.bookingTransfer.newDriver)})?`
      }
    },
    closeBookingTransferModal()
    {
      this.$bvModal.hide('transfer-bookings-modal')
      this.resetBookingTransferForm()
    },
    closeDriverModal()
    {
      this.$bvModal.hide('add-driver')
      this.resetDriverForm()
    },
    onDriverSelected(driver)
    {
      if (driver.vehicle_id === null) {
        this.$toastr.e('Selected Driver has no vehicle', 'Error')
        this.driverForm.vehicle = null
        return
      }
      this.axios
        .get(
          `/v1/route-itineraries/${this.itinerary.id}/vehicles/${driver.vehicle_id}/cost-of-supply`
        )
        .then((res) =>
        {
          if (res?.data?.cost_of_supply === null) {
            this.driverForm.costOfSupply = null
          } else {
            this.driverForm.costOfSupply = res.data.cost_of_supply
          }
        })
        .catch((error) =>
        {

        })

      if (driver.vehicle) {
        this.driverForm.vehicle = driver.vehicle
      }
      if (this.editMode && this.driverForm.updateVehicle && driver.vehicle) {
        if (!this.driverForm.vehicle && driver.vehicle) {
          this.driverForm.vehicle = driver.vehicle
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/partials/sh-colors' as colors;

p,h1,h2,h3,h4,h5,h6,span,label{
  margin: 0 !important;
}
.driver-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  cursor: pointer;

  &:hover {
    color: #00d97e;
  }
}

.alert-info {
  background: colors.$sh-purple-200;
  color: colors.$sh-purple-800;
  border: none;
}
</style>
